.p-t-2{
	padding-top:2em;
}

.p-l-1{
	padding-left:1em;
}

.m-b-1{
	margin-bottom: 1em;
}

.m-b-05{
	margin-bottom: 0.5em;
}

.m-r-1{
	margin-right: 1em;
}

.m-b-2{
	margin-bottom: 2em;
}

.m-t-05{
	margin-top: 0.5em;
}
.m-t-1{
	margin-top: 1em;
}

.m-t-2{
	margin-top: 2em;
}

.m-t-3{
	margin-top: 3em;
}

.m-t-4{
	margin-top: 4em;
}

.pre{
	white-space: pre-line;
}

.pointer{
	cursor: pointer;
}