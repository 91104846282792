
.enterPersonalData{

	.field{
		padding-left: 0;
		margin-bottom: 15px;
	}
	h3{
		text-align: center;
	}
	button, a.btn {
		margin-top: 25px;
		min-width: 10em;
	}
}


.loginPage{

	float:none;
	margin: 0 auto;

	.confirmPrivacy{
		margin: 100px auto 0 auto;
	}

	form{
		padding-top:50px;
	}
	label{
		font-weight: normal;
	}
	button{
		margin-top: 20px;
	}
	.btn-primary[type=submit]{
		float: none;
		/*width: 10em;*/
	    /*margin-left: 5em;*/
	}
}

[data-route=frontpage]{
	.faqButton{
		min-width: 9em;
	}
	@media (max-width: 767px) {
		.btn-primary,
		.btn-default,
		.faqButton {
			width:100%;
		}
	}
}